import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react"
import { navigate } from "gatsby"
import React, { FormEvent, useState } from "react"
import {
  Authenticated,
  useAuthenticated,
} from "../../components/auth/Authenticated"
import { FormVStack } from "../../components/Form"
import { FormLayout } from "../../components/layout/FormLayout"

function UpdateEmailForm() {
  const { user } = useAuthenticated()
  const [newEmail, setNewEmail] = useState(user.email ?? "")

  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  async function handleSubmit(e: FormEvent) {
    e.preventDefault()
    setIsLoading(true)

    setError("")

    if (newEmail) {
      try {
        await user.updateEmail(newEmail)
        await user.sendEmailVerification()
        navigate("/account/verify-email")
      } catch (err) {
        setError(err.message)
      }
    }

    setIsLoading(false)
  }

  if (!user.email) {
    throw new Error("Email is not defined")
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormVStack>
        {error && (
          <Alert status="error">
            <AlertIcon />
            {error}
          </Alert>
        )}
        <FormControl>
          <FormLabel>Email address</FormLabel>
          <Input
            value={newEmail}
            onChange={e => setNewEmail(e.target.value)}
            type="email"
            size="lg"
            required
          />
        </FormControl>
        <Button
          type="submit"
          colorScheme="blue"
          size="lg"
          // Don't enable unless the email was modified
          isDisabled={newEmail === user.email}
          isLoading={isLoading}
        >
          Save
        </Button>
      </FormVStack>
    </form>
  )
}

export default function UpdateEmail() {
  return (
    <FormLayout title="✉️ Update Email" showBack>
      <Authenticated>
        <UpdateEmailForm />
      </Authenticated>
    </FormLayout>
  )
}
